import React, { useMemo } from "react";

import { IntlProvider } from "react-intl";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { getMessagesFromLocale } from "../i18n";
import CallbackView from "./callback";
import InviteCompleteFeedbackView from "./invite-complete-feedback";
import InviteFeedbackView from "./invite-feedback";
import LandingView from "./landing";
import LogoutView from "./logout";
import AlreadyConnectedToTsId from "./sso/already-connected-to-tsid";
import ConnectAccountFeedback from "./sso/connect-account-feedback";
import ConnectTSIdToDigitalAccount from "./sso/connect-tsid-to-digital-account";
import FirstAccess from "./sso/first-access";
import TsIdGate from "./ts-id-gate";
import ForgotPassword from "./login/forgot-password";
import ResetPassword from "./login/reset-password";
import { getUserLocale } from "../i18n";

const AppRoutes = ({ userLocale = getUserLocale() }) => {
    const messages = useMemo(() => getMessagesFromLocale(userLocale), [userLocale]);

    return (
        <IntlProvider key={userLocale} locale={userLocale} messages={messages} timeZone="it-IT">
            <BrowserRouter>
                <Routes>
                    <Route element={<InviteFeedbackView />} path="/inviteFeedback" />
                    <Route element={<InviteCompleteFeedbackView />} path="/inviteCompleteFeedback" />
                    <Route element={<LandingView />} path="/" />
                    <Route element={<CallbackView />} path="/callback" />
                    <Route element={<LogoutView />} path="/logout" />
                    <Route element={<ForgotPassword />} path="/forgotPassword" />
                    <Route element={<ResetPassword />} path="/resetPassword" />
                    <Route element={<AlreadyConnectedToTsId />} path="/alreadyConnectedToTsId" />
                    <Route
                        path="*"
                        element={
                            <TsIdGate>
                                <Routes>
                                    <Route element={<FirstAccess />} path="/firstAccess" />
                                    <Route
                                        element={<ConnectTSIdToDigitalAccount />}
                                        path="/connectTSIdToDigitalAccount"
                                    />
                                    <Route element={<ConnectAccountFeedback />} path="/connectAccountFeedback" />
                                </Routes>
                            </TsIdGate>
                        }
                    />
                </Routes>
            </BrowserRouter>
        </IntlProvider>
    );
};

export default AppRoutes;
