import React, { ReactNode, useMemo } from "react";

import { Button } from "@vapor/react-material";

import generateTsIdLoginUrl from "../../../../utils/generate-tsid-login-url";
import FlowType from "../../../../types/FlowType";

export type TsIdLoginButtonProps = {
    appName?: string;
    children?: ReactNode;
    disabled?: boolean;
    flowType?: FlowType;
    variant: "contained" | "outlined" | "text";
};
const TsIdLoginButton = ({
    appName,
    children,
    disabled,
    variant = "contained",
    flowType = "default"
}: TsIdLoginButtonProps) => {
    const loginUrl = useMemo(() => {
        return generateTsIdLoginUrl(appName || "app", flowType);
    }, [appName, flowType]);

    return (
        <a href={loginUrl}>
            <Button disabled={disabled} variant={variant} fullWidth={true}>
                {children}
            </Button>
        </a>
    );
};

export default TsIdLoginButton;
