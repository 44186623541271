import React from "react";
import PropTypes from "prop-types";
import { Image, Initials } from "./styled";

type AvatarProps = {
    image: any;
    name: string;
    surname: string;
};
const Avatar = ({ image, name, surname }: AvatarProps) => {
    const getInitial = (word: string) => word.charAt(0).toUpperCase();

    if (image) {
        return <Image image={image} />;
    }

    return (
        <Initials>
            {getInitial(name)}
            {getInitial(surname)}
        </Initials>
    );
};

Avatar.propTypes = {
    image: PropTypes.any,
    name: PropTypes.string,
    surname: PropTypes.string
};

export default Avatar;
