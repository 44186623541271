import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styled from "styled-components";

export const Description = styled.div`
    color: rgb(90, 104, 114);
    font-size: 1rem;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.44px;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
`;

export const InfoBox = styled.div`
    background-color: ${props => props.theme.primary.surfaceDefaultSmoke};
    color: rgb(90, 104, 114);
    font-size: 1rem;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.44px;
    line-height: 1.5rem;
    padding: 1rem;
    margin-bottom: 1rem;
`;

export const InfoIcon = styled(FontAwesomeIcon)`
    color: ${props => props.theme.primary.textTitleColor};
    margin-right: 0.5rem;
    font-size: 1rem;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
