import React from "react";
import { FormattedMessage } from "react-intl";

import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Typography } from "@vapor/react-material";

import LoginModal from "../../components/general/login-modal";
import LoginModalContainer from "../../components/login/login-modal-container";
import tsIdV3Logout from "../../utils/tsid-v3-logout";
import { ButtonsContainer, ErrorIcon } from "./styled";
import { useAppSelector } from "../../hooks/redux";

const InviteFeedbackView = () => {
    const idToken = useAppSelector(state => state.tsIdV3.login.loginResult?.idToken);
    const tsIdEmail = useAppSelector(state => state.tsIdV3.login.tokenData.email);
    const callbackState = useAppSelector(state => state.tsIdV3.callbackState);

    return (
        <LoginModalContainer>
            <LoginModal>
                <ErrorIcon>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                </ErrorIcon>
                <Typography variant="titleLarge" style={{ fontWeight: "600", marginBottom: "1rem" }}>
                    <FormattedMessage id="v-invite-feedback.access-denied" />
                </Typography>
                <Typography variant="body1" color="#5a6872">
                    <FormattedMessage
                        id="v-invite-feedback.different-emails"
                        values={{
                            inviteEmail: callbackState.userEmail,
                            loggedEmail: tsIdEmail,
                            b: chunks => <b>{chunks}</b>
                        }}
                    />
                </Typography>
                <ButtonsContainer>
                    <Button
                        variant="contained"
                        onClick={() => {
                            tsIdV3Logout(idToken, callbackState);
                        }}
                    >
                        <FormattedMessage id="general.retry" />
                    </Button>
                    <Button variant="text" onClick={() => window.close()}>
                        <FormattedMessage id="general.annulla" />
                    </Button>
                </ButtonsContainer>
            </LoginModal>
        </LoginModalContainer>
    );
};

export default InviteFeedbackView;
