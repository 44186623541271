import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "@vapor/react-material";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";

import LoginModal from "../../../components/general/login-modal";
import LoginModalContainer from "../../../components/login/login-modal-container";
import { ButtonsContainer, Description, InfoBox, InfoIcon } from "./styled";
import { sendLoginCompleteEvent } from "../../../utils/send-login-complete-event";
import TsIdLoginButton from "../../../components/login/without-tsid/ts-id-login-button";
import { useAppSelector } from "../../../hooks/redux";

const AlreadyConnectedToTsId = () => {
    const auth = useAppSelector(state => state.auth);
    const loginOptions = useAppSelector(state => state.loginOptions);
    const mode = useAppSelector(state => state.tsIdV3.callbackState.mode);

    return (
        <LoginModalContainer>
            <LoginModal title={<FormattedMessage id="general.attention" />}>
                <Description>
                    <FormattedMessage
                        id="v-already-connected-to-tsid.from-date"
                        values={{
                            b: chunks => <b>{chunks}</b>
                        }}
                    />
                </Description>
                <Description>
                    <FormattedMessage
                        id="v-already-connected-to-tsid.ts-id-email"
                        values={{
                            email: auth.loginAuth.tsId,
                            b: chunks => <b>{chunks}</b>
                        }}
                    />
                </Description>
                <Description>
                    <FormattedMessage id="v-already-connected-to-tsid.forgot-password" />
                </Description>
                <InfoBox>
                    <InfoIcon icon={faInfoCircle} />
                    <FormattedMessage id="v-already-connected-to-tsid.info-box" />
                </InfoBox>
                <ButtonsContainer>
                    <TsIdLoginButton
                        appName={loginOptions.appName}
                        loginMethod={loginOptions.loginMethod}
                        variant="contained"
                    >
                        <FormattedMessage id="general.login-with-ts-id" />
                    </TsIdLoginButton>
                    {!auth.loginAuth.forceTsIdLogin && (
                        <Button
                            variant="text"
                            onClick={() => {
                                sendLoginCompleteEvent(
                                    auth.loginAuth.securityToken,
                                    auth.loginAuth.refreshToken,
                                    "",
                                    document.referrer,
                                    mode
                                );
                            }}
                        >
                            <FormattedMessage id="general.remind-me-later" />
                        </Button>
                    )}
                </ButtonsContainer>
            </LoginModal>
        </LoginModalContainer>
    );
};

export default AlreadyConnectedToTsId;
